.bug-container {
	width: 100%;
	min-height: 200px;
	border-top: 5px solid white;
	position: relative;
	z-index: 999;

	@media only screen and (min-width: 1200px) {
		top: -50px;
		padding-bottom: 50px;
		background-color: #ffffff40; 
	}

	@media only screen and (max-width: 1199px) {
		padding-bottom: 30px;
		background-color: #3f607d;
	}

	@media only screen and (max-width: 767px) {
		padding-bottom: 30px;
	}

	/* Everything up until 560px */
	@media only screen and (max-width: 560px) {
		padding-bottom: 20px;
	}

	&__title {
		color: white;
		font-size: 20px;
		text-align: center;
		padding-top: 40px;
		img {
			width: 40px;
		}
		div {
			padding-top: 15px;
		}

		@media only screen and (min-width: 1200px) {
			display: none;
		}

		@media only screen and (max-width: 560px) {
			font-size: 19px;
			img {
				width: 35px;
			}
		}
	}


	&__buggy-row {
		padding-top: 40px;
		position: relative;
		z-index: 100;
		width: 80%;
		margin: 0 auto;
	}

	/* Apply only when viewed on desktop */
	@media only screen and (min-width: 1200px) {
		&__buggy-row {
	    top: -120px;
	    position: absolute;
	    z-index: 100;
	    width: 81%;
	    margin: 0 auto;
	    left: 9%;
	  }
	}

	&__row {

		&-single-bug-container:hover {
			.bug-container__row-single-bug {

				/* Responsible for hovering over bug for NOT desktops*/
				@media only screen and (max-width: 1199px) {
					opacity: 0.9;
				}

				/* Responsible for hovering over bug for desktops*/
				@media only screen and (min-width: 1200px) {
					transform:translateY(-15px);
				}

			}
		}

		&-single-bug {
			border: 5px solid white;
			transition: transform 250ms ease;
			cursor: pointer;
			color: white;
			background-position: center;
      background-repeat: unset;
      background-size: cover;

			&-cockroach {
				background-image: url('./images/cockroach.jpg');
			}

			&-ant {
				background-image: url('./images/ant.jpg');
			}

			&-termite {
				background-image: url('./images/termite.jpg');
			
			}

			&-rodent {
				background-image: url('./images/rodent.jpg');
			}

			&-scorpion {
				background-image: url('./images/desert-scorpion.jpg');
			}

			&-spider {
				background-image: url('./images/spider.jpg');
			}

			&-text {
				background-color: rgba(0, 0, 0, 0.4);
		    text-align: center;
		    padding-top: 5px;
		    padding-bottom: 5px;
		    letter-spacing: 0.6px;
			}

			/* Everything after 1199px */
			@media only screen and (min-width: 1199px) {
					height: 130px;
			}

			/* Everything up until 1199px */
			@media only screen and (max-width: 1199px) {
					height: 200px;
					margin-bottom: 30px;
			}

			/* Everything up until 767px */
			@media only screen and (max-width: 767px) {
					height: 170px;
					margin-bottom: 30px;
			}

			/* Everything up until 560px */
			@media only screen and (max-width: 560px) {
					height: 130px;
					margin-bottom: 30px;
			}

		}
	}

	&__end-text {
		@media only screen and (max-width: 1200px) {
			display: none;
		}
    font-size: 18px;
    color: white;
    text-transform: uppercase;
    margin: 0 auto;
    position: relative;
    bottom: -91px;
    text-align: center;
    letter-spacing: 0.4px;
    font-weight: 100;

    span:nth-child(1) {
	   	border-bottom: 1px solid white;
	    padding-bottom: 2px;
	    font-weight: 400;
	    /*font-size: 19px;*/
	    margin-right: 5px;
	    cursor: pointer;
    }
	}

	&__down-arrow {
		@media only screen and (max-width: 1200px) {
			display: none;
		}
		font-size: 38px;
    color: white;
    margin: 0 auto;
    position: relative;
    bottom: -96px;
    text-align: center;
		.glyphicon {
		    cursor: pointer;
		}
	}

	&__footer {
		color: white;
		font-size: 20px;
		margin: 0 auto;
		padding-top: 5px;
		padding-bottom: 5px;
		text-align: center;
		margin-bottom: 10px;
		cursor: pointer;
		width: 180px;
		img {
			width: 33px;
		    filter: invert(1);
		    margin-right: 12px;
		    position: relative;
		    top: -2px;
		}
		div {
			padding-top: 15px;
		}

		@media only screen and (min-width: 1200px) {
			display: none;
		}

		@media only screen and (max-width: 560px) {
			font-size: 19px;
			img {
				width: 35px;
			}
		}
	}
	&__footer:hover {
		opacity: 0.8;
	}
}