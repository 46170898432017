.desktop-header {
	max-width: 1210px;
	margin: 0 auto;
}

.desktop-header-right {
	width: 100%;
	vertical-align: middle;
	text-align: right;
	display: inline-block;

	.glyphicon {
		padding-right: 7px;
		font-size: 16px;
		position: relative;
		top: 3px;
	}
	a {
		color: white;
		letter-spacing: 0.4px;
		font-weight: 100;
	}
	a:hover {
		color: #5bd45d;
		text-decoration: none;
	}

	&__phone {
		display: inline-block;
		margin-right: 15px;
		vertical-align: super;
	}
	&__pay-bill {
		display: inline-block;
		vertical-align: super;
		margin-left: 15px;
		margin-right: 15px;
		&-text {
			font-size: 12px;
		}
	}
	&__inspect {
		display: inline-block;
	    margin-left: 15px;
	    margin-right: 15px;
	    .emerald-button {
	    	height: 30px;
	    	border-radius: 20px;
	    	font-weight: 100;
	    	display: inline-table;
	    	padding-left: 15px;
	    	padding-right: 15px;
	    }
	}
}
