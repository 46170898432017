.page-nav-bar {
	background: #5fbc60;
	color: white;
	margin-bottom: 25px;
	&__line {
	  max-width: 1210px;
	  padding-top: 20px;
	  padding-bottom: 20px;
	  padding-left: 40px;
    margin: 0 auto;
    color: white;
    display: flex;
    font-size: 18px;
    font-weight: 300;
    div {
    	display: inline-block;
    }
    img {
    	width: 17px;
    	top: -2px;
    	position: relative;
    }
    img:hover {
    	opacity: 0.8;
    	cursor: pointer;
    }
    &-bracket {
    	padding-left: 7px;
    	padding-right: 7px;
    	img {
    		width: 12px;
    	}
    }
  }
}

.page-nav-bar-blue {
	background: #5fa2bc;
}

.page-nav-bar-red {
	background: #bc5f5f;
}

.page-nav-bar-yellow {
	background: #afb36d;
}

.page-nav-bar-purple {
    background: #958ad4;
}

@media only screen and (max-width: 767px) {
	.page-nav-bar {
		text-align: center;
		&__line {
			padding: 10px;
			font-size: 12px;
			/*margin: 0 auto;*/
			display: block;
		}
	}
}