.small-mobile-only {
	display: none;		
}

@media only screen and (max-width: 599px) {
	.small-mobile-only {
		display: block;
	}
}

.fixed-mobile-cta {
	width: 100%;
	position: fixed;
	z-index: 1101;
	bottom: 0;
	&__btn {
		display: inline-table;
		width: 50%;
    margin: 0 auto;
    font-size: 1.05em;
		transition: box-shadow 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94), background-color 150ms cubic-bezier(0.25, 0.46, 0.45, 0.94), border-color 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94), transform 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
		&-inspection {
			background-color: #2c8e2c;
		}
		&-call {
			background-color: #DBA633;
			.glyphicon-earphone {
				padding: 0;
				vertical-align: text-bottom;
				padding-right: 10px;
				margin: 0;
			    font-size: 1.2em;
			    position: relative;
			    top: -1px;
			}
		}
	}
}