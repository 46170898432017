.pest-page {
	&__body {
	    flex: 1 1 auto;
	    max-width: 1210px;
	    margin: 0 auto;
	    display: flex;
	    justify-content: space-between;
	    padding: 0;

	    &-left {
	    	flex: 0 0 30%;
		    order: -1;
		    max-width: 275px;
		    margin-left: 20px;
		    padding-top: 20px;
		    @media only screen and (max-width: 768px) {
				display: none;
			}
	    }

	    &-right {
	    	flex: 1 1 auto;
		    max-width: 850px;
		    width: 100%;
		    margin-left: 20px;
		    margin-right: 20px;
	    }

	    &-menu {
	    	&-fixed {
		    	position: fixed;
		    	top: 90px;
		    	width: 275px;
		    	z-index: 0;
		    	@media only screen and (max-width: 940px) {
						width: 230px;
					}
		    }
		    &-special-offers {
		    	text-align: center;
		    	padding-bottom: 20px;
		    	font-size: 20px;
		    	font-weight: 800;
		    	color: #b5b5b5;
		    }
		    &-discount {
		    	border: 2px dashed gray;
		    	padding: 10px;
		    	padding-top: 20px;
		    	padding-bottom: 20px;
		    	margin-bottom: 20px;
		    	&-header {
		    		text-align: center;
		    		font-size: 20px;
		    		color: #bc5f5f;
		    		text-transform: uppercase;
		    	}
		    	&-content {
		    		padding-top: 10px;
		    		text-align: center;
		    		font-size: 15px;
		    		padding-left: 15px;
		    		padding-right: 15px;
		    		font-weight: 100;
		    	}
		    	&-redeem {
		    		margin: 0 auto;
		    		text-align: center;
		    		margin-top: 20px;
		    		&-btn {
		    			background: #bc5f5f;
		    			height: 40px;
					    padding: 10px 25px 15px 25px;
					    margin: 0 auto;
					    text-align: center;
		    		}
		    	}
		    	&-more {
		    		color: #bc5f5f;
		    		font-size: 15px;
		    		text-align: center;
		    	}
		    	&-more:hover {
		    		color: #bc5f5f;
		    		opacity: 0.8;
		    	}
		    }
	    	&-link {
	    		padding: 15px;
	    		cursor: pointer;
	    		font-size: 15px;
	    		color: #7F7F7F;
	    		border-bottom: 1px solid #e8e8e8;
	    	}
	    	&-link:hover {
	    		background-color: #f5f5f5;
	    	}
	    	&-link-active {
	    		border-left: 5px solid #bc5f5f;;
	    		background-color: #f5f5f5;
	    	}
	    	&-backtop {
	    		padding-top: 10px;
	    		text-transform: uppercase;
			    font-size: 11px;
			    letter-spacing: 0.2px;
			    color: gray;
			    cursor: pointer;
			    margin-bottom: 20px;
	    	}
	    	&-backtop:hover {
	    		color: black;
	    	}
	    }

	    &-bugs {
	    	border-bottom: 1px solid #eee;
	    	padding-bottom: 20px;
	    	padding-top: 20px;
	    	@media only screen and (max-width: 768px) {
					padding-bottom: 20px;
				}

	    	h1 {
	    		font-weight: 800;
	    		font-size: 35px;
	    		color: #7F7F7F;
	    		margin-bottom: 20px;
	    		margin-top: 0;
	    		@media only screen and (max-width: 768px) {
					text-align: center;
					margin-top: 0;
					font-size: 25px;
				}
	    	}

	    	&-intro {
	    		font-size: 16px;
			    line-height: 23px;
			    font-style: italic;
			    padding-bottom: 10px;
	    	}

	    	&-content {
	    		font-size: 17px;
	    		font-weight: 300;
	    		line-height: 26px;
	    		min-height: 270px;
	    		&-picture {
	    			width: 100%;
	    			background-size: contain;
				    background-position: center;
				    // border: 1px solid black;
				    height: 306px;
				    margin-bottom: 20px;
				    background-repeat: no-repeat;
	    			&-ant {
	    				background-image: url('./images/ant.jpg');
	    			}
	    			&-bee {
	    				background-image: url('./images/bee.jpg');
	    			}
	    			&-cockroach {
	    				background-image: url('./images/cockroach.jpg');
	    			}
	    			&-cricket {
	    				background-image: url('./images/cricket.jpg');
	    			}
	    			&-rodent {
	    				background-image: url('./images/rodent.jpg');
	    			}
	    			&-desertscorpion {
	    				background-image: url('./images/desert-scorpion.jpg');
	    			}
	    			&-barkscorpion {
	    				background-image: url('./images/bark-scorpion.jpg');
	    			}
	    			&-spider {
	    				background-image: url('./images/spider.jpg');
	    			}
	    			&-termite {
	    				background-image: url('./images/termite.jpg');
	    			}
	    		}
	    		&-description {
    			    background: #f5f5f5;
				    padding: 20px;
				    min-height: 306px;
				    margin-bottom: 20px;
				    border-radius: 10px;
				    @media only screen and (max-width: 598px) {
				    	margin: 20px;
				    }
	    		}
	    		&-info-bold {
	    			font-weight: 800;
	    		}
	    		&-info {
	    			padding-bottom: 10px;
	    		}
	    	}
	    	
	    }

	    &-mobile-index {
	    	@media only screen and (min-width: 769px) {
				display: none;
			}
	    	text-align: center;
	    	div {
	    		display: inline-block;
			    padding: 5px 5px 0px 5px;
			    color: green;
			    font-size: 17px;
			    // font-weight:800;
			    margin-right: 10px;
			    margin-left: 10px;
			    margin-bottom: 10px;
			    // border-radius: 10px;
			    border-bottom: 1px solid green;
			    // text-decoration: underline;
	    	}	
	    }

	}
}