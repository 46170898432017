.facebook-rating {
	width: 180px;
	height: 80px;
	background: white;
	border-top: 8px solid #6e90d6;
	border-radius: 2px;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.26)!important;
	cursor: pointer;
	text-align: left;
	// transition: box-shadow 50ms;

	&__container {
		height: 100%;
	}

	&__logo {
		height: 100%;
		display: inline-block;
		vertical-align: middle;
	    width: 62px;
   		padding-left: 13px;	
   		svg {
   			width: 44px!important;
		    height: 44px!important;
		    padding: 4px!important;
		    border-radius: 50%!important;
		    background-color: #3c5b9b!important;
   		}
	}

	&__text {
		position: relative;
	    height: 100%;
	    font-family: "Helvetica Neue";
	    color: #666666;
	    display: inline-block;
	    width: 118px;
	    top: 6px;
	    padding-left: 6px;
	}

	&__description {
		padding-top: 2px;
		font-size: 13px;
	}

	&__rating {
		&-number {
			display: inline-block;
		    color: #3c5b9b;
		    padding-right: 5px;
		    position: relative;
		    top: -1px;

		}
		&-stars {
			display: inline-block;
			color: #3c5b9b;
		}
	}

	&__description-2 {
		padding-top: 2px;
		font-size: 13px;
	}
}

.facebook-rating:hover {
	border-top: 8px solid #4a9aff;
    box-shadow: 0 15px 20px 0 rgba(0,0,0,.26)!important;
}