.pestreview-rating {
	width: 180px;
	height: 80px;
	background: white;
	border-top: 8px solid #ebc808;
	border-radius: 2px;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.26)!important;
	cursor: pointer;
	text-align: left;
	// transition: box-shadow 50ms;

	&__container {
		height: 100%;
	}

	&__logo {
		height: 100%;
		display: inline-block;
		vertical-align: middle;
	    width: 62px;
   		padding-left: 13px;	
   		div {
   			height: 100%;
			width: 94%;
   			background-image: url('./pestreviewsLogo.jpg');
			background-size: contain;
			background-repeat: no-repeat;
   		}
	}

	&__text {
		position: relative;
	    height: 100%;
	    font-family: "Helvetica Neue";
	    color: #666666;
	    display: inline-block;
	    width: 118px;
	    top: 6px;
	    padding-left: 6px;
	}

	&__description {
		font-size: 12px;
		padding-top: 2px;
	}

	&__rating {
		&-number {
			display: inline-block;
		    color: #d6b500;
		    padding-right: 5px;
		    position: relative;
		    top: -1px;

		}
		&-stars {
			display: inline-block;
			color: #ebc808;
		}
	}

	&__description-2 {
		padding-top: 2px;
		font-size: 13px;
	}
}

.facebook-rating:hover {
	border-top: 8px solid #4a9aff;
    box-shadow: 0 15px 20px 0 rgba(0,0,0,.26)!important;
}