body {
  margin: 0;
  font-family: 'Lato', Helvetica, Arial, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",monospace;
}

nav ol, nav ul {
	list-style: none;
}

html,
body {
	height: 100%;
	width: 100%;
}

#app {
	min-height: 100%;
	min-width: 100%;
}

#root {
  margin: 0 auto;
  display: flex;
  min-height: 100%;
  flex-direction: column;
}


.emerald-button {
	display: inline-block;
	user-select: none;
	cursor: pointer;
	text-align: center;
	height: 50px;
	color: white;
	transition: box-shadow 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94), background-color 150ms cubic-bezier(0.25, 0.46, 0.45, 0.94), border-color 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94), transform 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94), filter 250ms;
	background-color: #2c8e2c;
}

.emerald-button-loading {
	display: inline-block;
	user-select: none;
	text-align: center;
	height: 50px;
	color: white;
	transition: box-shadow 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94), background-color 150ms cubic-bezier(0.25, 0.46, 0.45, 0.94), border-color 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94), transform 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94), filter 250ms;
	background-color: #76ad76;
	cursor: not-allowed;
}
.emerald-button-disabled {
	display: inline-block;
	user-select: none;
	text-align: center;
	height: 50px;
	color: white;
	transition: box-shadow 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94), background-color 150ms cubic-bezier(0.25, 0.46, 0.45, 0.94), border-color 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94), transform 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94), filter 250ms;
	background-color: #76ad76;
	cursor: not-allowed;
}
.emerald-button:active {
	box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
	filter: brightness(0.9) !important;
	transform: translateY(2px);
}
.emerald-button:hover {
	filter: brightness(1.15);
}
.emerald-button-content {
	display: table-cell;
	vertical-align: middle;
}
.reactdomlink {
	text-decoration: none;
	color: white;
}
.reactdomlink:hover {
	color: white;
	text-decoration: none;
}

@media only screen and (max-width: 599px) {
	.fb_dialog {
		bottom: 51pt !important;
		right: 12pt !important;
	}
	.fb_dialog.fb_dialog_advanced {
		bottom: 51pt !important;
		right: 12pt !important;
	}
	iframe.fb_customer_chat_bounce_in_v2 {
		bottom: 51pt !important;
		right: 12pt !important;
	}
	iframe.fb_customer_chat_bounce_out_v2 {
		bottom: 51pt !important;
		right: 12pt !important;
	}
}