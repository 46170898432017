.fivestep-container {
	width: 100%;
	background-color: #FFFFFF;

	&__icon {
		padding-top: 40px;
		width: 44px;
		text-align: center;
		margin: 0 auto;
		@media only screen and (max-width: 560px) {
			width: 40px;
		}
		img {
			width: 100%;
			opacity: 0.4;
		}
	}
	&__header {
		font-size: 25px;
		text-align: center;
		color: #7F7F7F;
		padding-top: 11px;

		@media only screen and (max-width: 1199px) {
			font-size: 20px;
		}
		
		@media only screen and (max-width: 560px) {
			font-size: 19px;
			padding-top: 15px;
		}
	}

	&__header-border {
		&-top {
			width: 350px;
		    border-top: 1px solid #cccccc;
		    margin: 0 auto;
		    margin-top: 22px;
			@media only screen and (max-width: 1199px) {
				width: 300px;
			}
		    @media only screen and (max-width: 560px) {
				display: none;
			}
		}
		&-bottom {
			width: 220px;
		    border-top: 1px solid #adadad;
		    margin: 0 auto;
		    margin-top: 11px;
		    @media only screen and (max-width: 1199px) {
				width: 190px;
			}
		    @media only screen and (max-width: 560px) {
				display: none;
			}
		}
	}

	&__subtext {
		width: 830px;
    font-size: 21px;
    font-weight: 100;
    margin: 0 auto;
    text-align: center;
    padding-top: 25px;
    color: #7F7F7F;

	  @media only screen and (max-width: 900px) {
			width: 85%;
		}
		@media only screen and (max-width: 560px) {
			display: none;
		}
	}

	&__down-arrow {
		font-size: 38px;
    color: #cacaca;
    margin: 0 auto;
    position: relative;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 10px;
    	.glyphicon {
		    cursor: pointer;
    	}
	}

	&__five-steps {
		padding-top: 10px;
	}

	&__first-row, &__second-row {
		margin: 0;
	}

	&__col {
		padding: 0px;
		padding-left: 5px;
		padding-bottom: 5px;
		@media only screen and (max-width: 767px) {
			padding-left: 0 !important;
			padding-right: 0 !important;
		}
	}

	&__col-2 {
		@media only screen and (max-width: 1199px) {
			padding-right: 5px;
		}
	}

	&__col-3, &__col-5 {
		padding-right: 5px;
		@media only screen and (max-width: 767px) {
			padding-right: 0;
		}
	}

	&__step-content {
		position: relative;
		position: relative;
    top: 50%;
    transform: translateY(-10%);
    transition: transform 500ms ease-out;
    @media only screen and (max-width: 599px) {
			transform: translateY(-50%);
		}
	}

	&__col:hover {
		@media only screen and (min-width: 600px) {
			.fivestep-container__step-content {
				transform: translateY(-50%);
				.fivestep-container__step-description {
					opacity: 1;
					padding-top: 10px;
				}
				.fivestep-container__learn-more-btn {
					opacity: 1;
				}
			}	
		}
	}

	&__step-header {
		color: white;
    font-size: 22px;
    text-align: center;
	}

	&__step-description {
		color: white;
    font-size: 16px;
    text-align: center;
    padding: 25px;
    padding-top: 20px;
    font-weight: 100;
    opacity: 0;
    transition: opacity 500ms, padding-top 500ms;
    @media only screen and (max-width: 599px) {
			opacity: 1;
		}
	}

	&__learn-more-btn {
		display: block;
    padding: 12px 33px 12px 33px;
    margin: 0 auto;
    text-align: center;
    transition: opacity 500ms;
    width: 136px;
    height: 45px;
    transition: opacity 500ms;
    opacity: 0;
    @media only screen and (max-width: 599px) {
			opacity: 1;
		}
	}

	&__single-step {
    overflow: hidden;
	}

	&__step-power-spray {
		background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('./images/power-spray.jpg');
		height: 300px;
		background-size: cover;
	}

	@media only screen and (min-width: 600px) {
		&__step-power-spray:hover {
			background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6)), url('./images/power-spray.jpg');
		}
	}

	&__step-granules {
		background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('./images/granules.jpg');
		height: 300px;
		background-size: cover;
    background-position: top;
	}

	@media only screen and (min-width: 600px) {
		&__step-granules:hover {
			background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./images/granules.jpg');
		}
	}

	&__step-dust-injections {
		background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('./images/dust-injections.jpg');
		height: 300px;
		background-size: cover;
    background-position: right;
	}
	
	@media only screen and (min-width: 600px) {
		&__step-dust-injections:hover {
			background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./images/dust-injections.jpg');
		}
	}

	&__step-garage {
		background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./images/garage.jpg');
		height: 350px;
		background-size: cover;
		background-position: center;
	}

	@media only screen and (min-width: 600px) {
		&__step-garage:hover {
			background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('./images/garage.jpg');
		}
	}

	&__step-dust-deweb {
		background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('./images/deweb.jpg');
		height: 350px;
		background-size: cover;
		@media only screen and (max-width: 767px) {
			background-position: center;
		}
	}

	@media only screen and (min-width: 600px) {
		&__step-dust-deweb:hover {
			background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./images/deweb.jpg');
		}
	}

	&__padding-bottom {
		width: 100%;
		height: 30px;
		@media only screen and (min-width: 1200px) {
			height: 30px;
		}
	}
}