.blog-page {
	&__emptyspace {
		height: 200px;
		 @media only screen and (max-width: 767px) {
			display: none;
		}
	}
	&__body {
	    flex: 1 1 auto;
	    max-width: 1210px;
	    margin: 0 auto;
	    display: flex;
	    justify-content: space-between;
	    padding: 0;

	    &-left {
	    	flex: 0 0 30%;
		    order: -1;
		    max-width: 275px;
		    margin-left: 20px;
		    padding-top: 20px;
		    @media only screen and (max-width: 768px) {
				display: none;
			}
	    }

	    &-right {
	    	flex: 1 1 auto;
		    max-width: 850px;
		    width: 100%;
		    margin-left: 20px;
		    margin-right: 20px;
		    /*padding-bottom: 210px;*/
	    }

	    &-menu {
	    	&-date {
	    		display: inline-block;
	    		font-size: 12px;
	    		font-weight: 800;
	    		font-style: italic;
	    	}
	    	&-fixed {
		    	position: fixed;
		    	top: 90px;
		    	width: 275px;
		    	z-index: 0;
		    	@media only screen and (max-width: 940px) {
					width: 230px;
				}
		    }
		    &-special-offers {
		    	text-align: center;
		    	padding-bottom: 20px;
		    	font-size: 20px;
		    	font-weight: 800;
		    	color: #b5b5b5;
		    }
		    &-discount {
		    	border: 2px dashed gray;
		    	padding: 10px;
		    	padding-top: 20px;
		    	padding-bottom: 20px;
		    	margin-bottom: 20px;
		    	&-header {
		    		text-align: center;
		    		font-size: 20px;
		    		color: #958ad4;
		    		text-transform: uppercase;
		    	}
		    	&-content {
		    		padding-top: 10px;
		    		text-align: center;
		    		font-size: 15px;
		    		padding-left: 15px;
		    		padding-right: 15px;
		    		font-weight: 100;
		    	}
		    	&-redeem {
		    		margin: 0 auto;
		    		text-align: center;
		    		margin-top: 20px;
		    		&-btn {
		    			height: 40px;
					    padding: 10px 25px 15px 25px;
					    margin: 0 auto;
					    text-align: center;
					    background-color: #958ad4;
		    		}
		    	}
		    	&-more {
		    		color: #958ad4;;
		    		font-size: 15px;
		    		text-align: center;
		    	}
		    	&-more:hover {
		    		opacity: 0.9;
		    		color: #958ad4;;
		    	}
		    }
	    	&-link {
	    		padding: 15px;
	    		cursor: pointer;
	    		font-size: 15px;
	    		color: #7F7F7F;
	    		border-bottom: 1px solid #e8e8e8;
	    	}
	    	&-link:hover {
	    		background-color: #f5f5f5;
	    	}
	    	&-link-active {
	    		border-left: 5px solid #958ad4;;;
	    		background-color: #f5f5f5;
	    	}
	    	&-backtop {
	    		padding-top: 10px;
	    		text-transform: uppercase;
			    font-size: 11px;
			    letter-spacing: 0.2px;
			    color: gray;
			    cursor: pointer;
			    margin-bottom: 20px;
	    	}
	    	&-backtop:hover {
	    		color: black;
	    	}
	    }

	    &-post1 {
	    	@media only screen and (max-width: 768px) {
				border-bottom: 1px solid #ccc;
				padding-bottom: 20px;
			}
	    	padding-bottom: 10px;
	    	h1 {
	    		font-weight: 800;
	    		font-size: 35px;
	    		color: #7F7F7F;
	    		margin-bottom: 20px;
	    		@media only screen and (max-width: 768px) {
					text-align: center;
					margin-top: 0;
					font-size: 25px;
				}
	    	}
	    	h5 {
	    		margin-top: -5px;
	    		margin-bottom: 20px;
	    		@media only screen and (max-width: 768px) {
					text-align: center;
				}
	    	}
	    	&-content {
	    		font-size: 17px;
	    		font-weight: 300;
	    		line-height: 26px;
	    		img {
	    			width: 200px;
	    			float: left;
	    			margin-right: 15px;
	    			padding-top: 5px;
	    			height: 125px;
	    			border-radius: 100px;
	    		}
	    	}
    		clear: both;
	    }
	}
}