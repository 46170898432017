.review-container {
	background-color: #323232;
	width: 100%;
	/*border-top: 5px solid white;*/
	padding-bottom: 30px;

	&__stars {
		color: #37A238;
		font-size: 20px;
		text-align: center;
		margin: 0 auto;
		padding-top: 40px;
		padding-bottom: 20px;
	}

	&__review {
		color: white;
	    font-style: italic;
	    font-weight: 100;
	    font-size: 19px;
	    width: 80%;
	    text-align: center;
	    margin: 0 auto;
	    &-text{
	    	line-height: 30px;
	    }
	    &-emma {
	    	position: absolute;
		    border: 2px solid white;
		    margin-left: 10px;
		    margin-top: 1px;
		    border-radius: 100px;
	    	display: inline-block;
	    	width: 26px;
			height: 26px;
			background-image: url('./emma2.jpg');
			background-size: cover;
	    }
	}

	&__read-reviews {
		display: block;
		position: relative;
    	margin: 0 auto;
    	text-align: center;
    	margin-top: 30px;

		.emerald-button {
		    display: inline-table;
		    padding: 15px 45px 15px 45px;
		    margin: 0 auto;
		    text-align: center;
		}

	}

	&__ratings {
		padding-top: 25px;
		margin: 0 auto;
		text-align: center;
		&-pestroutes {
			padding-left: 20px;
			padding-right: 20px;
			display: inline-block;
			vertical-align: middle;
			padding-bottom: 20px;
		}
		&-google {
			display: inline-block;
			vertical-align: middle;
			padding-left: 20px;
			padding-right: 20px;
			padding-bottom: 20px;
		}
		&-facebook {
			display: inline-block;
			vertical-align: middle;
			padding-left: 20px;
			padding-right: 20px;
			padding-bottom: 20px;

		}
	}
}

a .pestroutesReviewsBadge.wide {
    box-shadow: 0 4px 0 #edc70a inset !important;
    border-top: 4px solid #edc70a !important;
}

a .pestroutesReviewsBadge.wide:hover {
    border-top: 4px solid #f9d519 !important;
    box-shadow: 0 4px 0 #f9d519 inset !important;

}

a .pestroutesReviewsBadge.orange.wide {
	margin: 0 !important;
}