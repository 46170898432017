.footer-container {
	background-color: #F1F0F0;
	overflow: hidden;
	z-index: 1;
	position: relative;

	&__copyright {
		text-align: center;
		color: #949494;
		padding-top: 15px;
		padding-bottom: 8px;
		@media only screen and (max-width: 599px) {
			padding-bottom: 70px;
		}
	}

	&__createdby {
	    font-size: 12px;
	    font-weight: 700;
	    color: #6f6f6f;
	    letter-spacing: .5px;
	    text-align: center;
	    padding-bottom: 15px;
	}

	&__privacy-policy {
		margin-top: 5px;
		font-size: 12px;
	}

	&__col-right {
		padding-top: 40px;
		@media only screen and (min-width: 768px) {
			padding-left: 60px;
		}
	}

	&__free-inspection-btn {
		display: block;
	    padding: 12px 25px 12px 25px;
	    text-align: center;
	    width: 200px;
	    height: 45px;
	    .emerald-button-content {
	    	display: block;
	    }
	    @media only screen and (max-width: 767px) {
			margin: 0 auto;
		}
		@media only screen and (max-width: 599px) {
			display: none;
		}
	}

	&__offers-btn {
		background-color: #dca71f;
		margin-top: 20px;
		display: block;
	    padding: 12px 25px 12px 25px;
	    text-align: center;
	    width: 200px;
	    height: 45px;
	    .emerald-button-content {
	    	display: block;
	    }
	   	@media only screen and (max-width: 767px) {
			margin: 0 auto;
			margin-top: 20px;
		}
		@media only screen and (max-width: 599px) {
			margin-top: 0px;
		}
	}

	&__border-1 {
		border-top: 1px solid #ababab;
		width: 300px;
		margin: 0 auto;
	}

	&__col-mid {
		padding-top: 40px;
	}

	&__links {
		text-align: center;
		a {
			text-decoration: none;
			color: #909090;
		}
		a:hover {
			color: #2F8D2F;
		}
	}

	&__links-col {
		padding-bottom: 10px;
		/*white-space: nowrap;*/
		text-align: center;
		@media only screen and (max-width: 1080px) {
			font-size: 13px;
		}
		@media only screen and (max-width: 767px) {
			font-size: 15px;
		}
	}

	&__col-left {
		@media only screen and (min-width: 768px) {
			padding-top: 40px;
			padding-right: 50px;
			text-align: right;
		}
		@media only screen and (max-width: 767px) {
			text-align: center;
			padding-top: 10px;
		}
		@media only screen and (max-width: 599px) {
			padding-top: 0;
		}

	}

	&__connect {
		color: gray;
		@media only screen and (min-width: 899px) {
			padding-right: 50px;
		}
		@media only screen and (max-width: 899px) {
			padding-right: 5px;
		}
	}

	&__social-media-btn {
		cursor: pointer;
		width: 30px;
		margin: 15px;
		@media only screen and (max-width: 899px) {
			width: 22px;
			margin: 8px;
		}
		@media only screen and (max-width: 599px) {
			width: 30px;
			margin: 10px;
		}
	}
	&__social-media-btn:hover {
		filter: brightness(0.8);
	}

	&__review-btn {
		margin-right: 39px;
	    text-align: center;
	    width: 150px;
	    height: 38px;
	    padding-top: 9px;
	    margin-top: 6px;
	    .emerald-button-content {
	    	display: block;
	    }
	    @media only screen and (max-width: 899px) {
			margin-right: 0px;
		}
		@media only screen and (max-width: 599px) {
			margin-top: 10px;
		}
	}

	&__border-2 {
		border-top: 1px solid #ababab;
		width: 300px;
		margin: 0 auto;
		margin-top: 20px;
		padding-bottom: 20px;
	}

	&__phone {
		@media only screen and (max-width: 767px) {
			display: none;
		}
		a{
			color: #2F8D2F;
			text-decoration: none;
		}
		color: #2F8D2F;
		cursor: pointer;
		font-size: 17px;
		text-align: center;
		span:nth-child(1) {
			font-size: 25px;
		}
		span:nth-child(2) {
			padding-left: 10px;
			vertical-align: super;
		}
	}
	&__phone:hover {
		filter: brightness(1.1);
	}
}