.goodhands-container {
	width: 100%;
	background-color: white;
	padding-bottom: 40px;

	&__icon {
		padding-top: 40px;
		width: 54px;
		text-align: center;
		margin: 0 auto;
		opacity: 0.6;
		@media only screen and (max-width: 560px) {
			width: 47px;
		}
		img {
			width: 100%;
		}
	}
	&__header {
		font-size: 25px;
		text-align: center;
		color: #7F7F7F;
		padding-top: 11px;

		@media only screen and (max-width: 1199px) {
			font-size: 20px;
		}
		
		@media only screen and (max-width: 560px) {
			font-size: 19px;
			padding-top: 15px;
		}
	}

	&__header-border {
		&-top {
			width: 390px;
		    border-top: 1px solid #cccccc;
		    margin: 0 auto;
		    margin-top: 17px;
			@media only screen and (max-width: 1199px) {
				width: 300px;
			}
		    @media only screen and (max-width: 560px) {
				display: none;
			}
		}
		&-bottom {
			width: 240px;
		    border-top: 1px solid #adadad;
		    margin: 0 auto;
		    margin-top: 11px;
		    @media only screen and (max-width: 1199px) {
				width: 190px;
			}
		    @media only screen and (max-width: 560px) {
				display: none;
			}
		}
	}

	&__subtext {
		width: 830px;
	    font-size: 21px;
	    font-weight: 100;
	    margin: 0 auto;
	    text-align: center;
	    padding-top: 25px;
	    color: #7F7F7F;
	    span {
	    	font-weight: 600;
	    }

	  @media only screen and (max-width: 900px) {
			width: 85%;
		}
		@media only screen and (max-width: 560px) {
			font-size: 18px;
		}
	}

}