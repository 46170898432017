.carousel_banner-container {
    position: relative;
    height: 600px;
    width: 100%;
    // margin-top: 75px;
    // @media only screen and (max-width: 768px) {
    //     margin-top: 0;
    // }

    .mainvideo {
        @media only screen and (max-width: 768px) {
            display: none;
        }
        width: 100%;
        height: 750px;
        overflow: hidden;
        position: absolute;
        video {
            width: 100%;
            background: black;
            background-size: cover;
            background-repeat: no-repeat;
            @media only screen and (min-width: 846px) {
                   position: fixed;
                    top: 59px;
                    z-index: -99999;
                    height: 838px;
                    object-fit: cover;
            }
            @media only screen and (max-width: 1346px) {
                width: 120%;
            }
             @media only screen and (max-width: 889px) {
                width: 120%;
            }
        }
        &-dots {
            height: 838px;
            width: 100%;
            top: 59px;
            position: fixed;
            z-index: -99999;

            background-image: -webkit-repeating-radial-gradient(center center, rgba(0,0,0,0.4), rgba(0,0,0,.1) 1px, transparent 1px, transparent 100%);
            background-image: -moz-repeating-radial-gradient(center center, rgba(0,0,0,.1), rgba(0,0,0,.1) 1.5px, transparent 1px, transparent 100%);
            background-image: -ms-repeating-radial-gradient(center center, rgba(0,0,0,.1), rgba(0,0,0,.1) 1.5px, transparent 1px, transparent 100%);
            background-image: repeating-radial-gradient(center center, rgba(0,0,0,.1), rgba(0,0,0,.1) 1.5px, transparent 1px, transparent 100%);
            background-size: 4px 4px;

            @media only screen and (max-width: 1346px) {
                width: 120%;
            }
             @media only screen and (max-width: 889px) {
                width: 120%;
            }
        }
        &-text-container {
            position: absolute;
            width: 100%;
            z-index: 1;
        }
        &-text-container-text {
            text-align: center;
            position: relative;
            top: 170px;
            color: white;
            font-size: 37px;
            @media only screen and (max-width: 1199px) {
               top: 130px;
            }
            @media only screen and (max-width: 900px) {
                font-size: 30px;
            }
        }
        &-text-container-subtext {
            text-align: center;
            position: relative;
            top: 140px;
            color: white;
            font-size: 16px;
            @media only screen and (min-width: 1200px) {
                display: none;
            }
        }
    }

	&-inspect-btn {
        position: relative;
        z-index: 12;
        margin: 0 auto;
        text-align: center;
        top: 44%;
        .emerald-button {
            display: inline-table;
            padding: 15px 61px 15px 61px;
            margin: 0 auto;
            text-align: center;
            // border: 1px #aaa solid;
            &-content {
                font-size: 15px;
                letter-spacing: 0.3px;
            }
        }
	}
    &-subtext {
        position: relative;
        z-index: 12;
        color: white;
        font-size: 18px;
        letter-spacing: 0.5px;
        text-align: center;
        top: 53%;
        width: 326px;
        margin: 0 auto;
        padding-bottom: 11px;
    }
    .carousel_banner-pagination {
        top: 15px;
        position: relative;
        color: white;
        text-align: center;
        &-filled {
            position: relative;
            top: 1px;
            padding-left: 2px;
            cursor: pointer;
            font-size: 20px;
            padding-right: 2px;
        }
        &-empty {
            padding-left: 2px;
            padding-right: 2px;

            cursor: pointer;
            font-size: 20px;
        }
    }

    .carousel_banner-container-left-control {
        z-index: 16;
        color: white;
        position: absolute;
        top: 45%;
        font-size: 35px;
        padding-left: 1%;
        opacity: 0.6;
        cursor: pointer;
    }
    .carousel_banner-container-left-control:hover {
        opacity: 0.9;
    }
    .carousel_banner-container-right-control {
        z-index: 16;
        color: white;
        position: absolute;
        top: 45%;
        font-size: 35px;
        padding-right: 1%;
        opacity: 0.6;
        right: 0;
        cursor: pointer;
    }
    .carousel_banner-container-right-control:hover {
        opacity: 0.9;
    }

	.carousel_banner-image {
		position: absolute;
        transition: opacity 1s ease-in;
        background-position: right;
        background-repeat: no-repeat;
        background-size: cover;
        height: 600px;
        @media only screen and (min-width: 769px) {
            display: none;
        }
        @media only screen and (min-width: 1200px) {
            height: 750px;
        }
        width: 100%;

        &-1 {
        	background-image: url('./images/1.jpg');
        }
        &-2 {
        	background-image: url('./images/2.jpg');
        }
        &-3 {
        	background-image: url('./images/4.jpg');
        }
        &-4 {
        	background-image: url('./images/3.jpg');
        }

        .carousel_banner-text-container {
        	color: white;
        	text-align: center;
        	position: relative;
        	top: 13%;
        	padding-left: 15px;
        	padding-right: 15px;
        }
		
		.carousel_banner-text {
	       font-size: 2.7em;
           /*font-weight: 600;*/
	       letter-spacing: 0.5px;
		}
		.carousel_banner-subtext {
			font-size: 1.3em;
			font-weight: 300;
			padding-top: 10px;
		}
	}

	.carousel_banner-active {
		opacity: 1;
		z-index: 1;
	}
	.carousel_banner-inactive {
		opacity: 0;		
		z-index: -1;
	}
    .carousel_banner-container-reviews {
        display: none;
    }
    .carousel_banner-container-inspect {
        display: block;
    }
}

@media only screen and (max-width: 1199px) {
    .carousel_banner-container {
        height: 500px;
        .carousel_banner-image {
            height: 500px;
            .carousel_banner-text-container {
                top: 25%;
                padding-left: 15px;
                padding-right: 15px;
            }
            .carousel_banner-text {
               font-size: 2.2em;
               letter-spacing: 0.5px;
            }
            .carousel_banner-subtext {
                font-size: 1.3em;
                font-weight: 300;
                padding-top: 10px;
            }
        }
        
        &-inspect-btn {
            position: relative;
            top: 55%;
        }
        &-subtext {
            top: 65%;
        }
    }
}

@media only screen and (max-width: 768px) {
    .carousel_banner-container {
        .carousel_banner-image {
            .carousel_banner-text-container {
                top: 25%;
                padding-left: 15px;
                padding-right: 15px;
            }
            .carousel_banner-text {
               font-size: 1.8em;
               letter-spacing: 0.5px;
            }
            .carousel_banner-subtext {
                font-size: 1.3em;
                font-weight: 300;
                padding-top: 10px;
            }
        }
        
        &-inspect-btn {
            position: relative;
            top: 55%;
        }
        &-subtext {
            top: 62%;
        }
    }
}

@media only screen and (max-width: 966px) {
    .carousel_banner-container {
        .carousel_banner-image {
            .carousel_banner-text {
               font-size: 2.1em;
               /*letter-spacing: 0.5px;*/
            }
        }
    }
}

@media only screen and (max-width: 599px) {
    .carousel_banner-container {
        &-mobile-arrows {
            display: none;
        }
        height: 500px;
        .carousel_banner-image {
            height: 500px;
            background-position: 94%, 0%;
            background-repeat: no-repeat;
            background-size: cover;
            .carousel_banner-text-container {
                top: 15%;
                padding-left: 20px;
                padding-right: 20px;
            }
            .carousel_banner-text {
               font-size: 1.8em;
               letter-spacing: 0.5px;
            }
            .carousel_banner-subtext {
                font-size: 1.2em;
                font-weight: 400;
                padding-top: 30px;
            }
        }
        .carousel_banner-container-right-control {
            top: 55.5%;
        }
        .carousel_banner-container-left-control {
            top: 55.5%;
        }
        &-inspect-btn {
            position: relative;
            top: 55%;
        }
        &-subtext {
            top: 75%;
            font-size: 15px;
            width: 260px;
            border: none;
            letter-spacing: 0px;
        }
        .carousel_banner-container-reviews {
            display: block;
            cursor: pointer;
        }
        .carousel_banner-container-inspect {
            display: none;
        }   
    }
}