.difference-container {
	width: 100%;
	background-color: #F1F0F0;
	/*border-top: 5px solid white;*/

	&__icon {
		padding-top: 40px;
		width: 44px;
		text-align: center;
		margin: 0 auto;
		@media only screen and (max-width: 560px) {
			width: 40px;
		}
		img {
			width: 100%;
			/*filter: invert(1);*/
		}
	}
	&__header {
		font-size: 25px;
		text-align: center;
		color: #7F7F7F;
		padding-top: 11px;

		@media only screen and (max-width: 1199px) {
			font-size: 20px;
		}
		
		@media only screen and (max-width: 560px) {
			font-size: 19px;
			padding-top: 15px;
		}
	}

	&__header-border {
		&-top {
			width: 400px;
		    border-top: 1px solid #cccccc;
		    margin: 0 auto;
		    margin-top: 22px;
			@media only screen and (max-width: 1199px) {
				width: 330px;
			}
		    @media only screen and (max-width: 560px) {
				display: none;
			}
		}
		&-bottom {
			width: 266px;
		    border-top: 1px solid #adadad;
		    margin: 0 auto;
		    margin-top: 11px;
		    @media only screen and (max-width: 1199px) {
				width: 190px;
			}
		    @media only screen and (max-width: 560px) {
				display: none;
			}
		}
	}

	&__subtext {
		width: 784px;
	    font-size: 21px;
	    font-weight: 100;
	    margin: 0 auto;
	    text-align: center;
	    padding-top: 25px;
	    color: #7F7F7F;

	    @media only screen and (max-width: 900px) {
			width: 85%;
		}
		@media only screen and (max-width: 560px) {
			display: none;
		}
	}

	&__differences {
		width: 80%;
		margin: 0 auto;
		padding-top: 50px;

		@media only screen and (max-width: 560px) {
			padding-top: 40px;
		}

		&-icon {
			background-image: url('./images/emerald.png');
			width: 40px;
		    height: 40px;
		    text-align: center;
		    background-size: contain;
		    background-repeat: no-repeat;
		    margin: 0 auto;
		    position: relative;
    		top: -15px;
    		@media only screen and (min-width: 600px) {
    			width: 50px;
		    	height: 50px;
    		}
		}

		&-header {
			font-size: 20px;
		    font-weight: 600;
		    text-align: center;
		    padding-bottom: 10px;
		}

		&-subtext {
			font-weight: 100;
		    text-align: center;
		    width: 268px;
		    font-size: 17px;
		    margin: 0 auto;
		    padding-bottom: 20px;
		    @media (max-width: 1199px) and (min-width: 600px) {
				width: 395px;
			    font-size: 19px;
			}
			@media (max-width: 599px) {
				padding-left: 13px;
    			padding-right: 13px;
			}
		}

		&-read-more {
			color: #2A7B2B;
			cursor: pointer;
			font-size: 16px;
			margin: 0 auto;
			text-align: center;
			padding-bottom: 20px;
		}

		&-read-more:hover {
			filter: brightness(1.3);
		}

	}

	&__difference {
		background-color: white;
		margin-bottom: 30px;
		z-index: 1;
		position: relative;
		@media only screen and (min-width: 1200px) {
			margin-left: 5px;
			margin-right: 5px;
		}
		@media only screen and (min-width: 1350px) {
			margin-left: 15px;
			margin-right: 15px;
		}
	}

	&__connecting-bar {
		height: 10px;
		width: 100%;
		background-color: white;
		position: relative;
		top: -140px;
		z-index: 0;
		@media only screen and (max-width: 1199px) {
			display: none;
		}
	}

	&__padding-bottom {
		width: 100%;
		height: 30px;
		@media only screen and (min-width: 1200px) {
			height: 30px;
		}
	}
}