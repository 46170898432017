.fake-header {
	display: none;
	transition: all 0.75s;
	@media only screen and (min-width: 768px) {
		height: 94px;
		width: 100%;
		display: block;
	}
}

.fake-header-fixed {
	@media only screen and (max-width: 845px) {
		height: 70px;
	}
}

.navbar__mobile-logo {
	left: 10px;
	position: relative;
	filter: brightness(1.3);
	background-image: url('./logo.png');
	width: 122px;
	height: 34px;
	background-repeat: no-repeat;
	background-size: contain;
}

.siteheaderdefault {
	padding: 19px 0px 19px 0;
  	background-color: white;
  	transition: height 0.75s, padding 0.75s;

	@media only screen and (min-width: 768px) {
	  	position: absolute;
		width: 100%;
		background: white;
		z-index: 2;
		top: 50px;
	  	height: 94px;
	}

  @media only screen and (max-width: 768px) {
    padding-bottom: 0;
  }
  @media only screen and (max-width: 599px) {
    background-color: #1d1d1d;
    margin-bottom: 3px;
  }
}

.desktop-fixed-header {
	@media only screen and (min-width: 768px) {
		position: fixed;
	    top: 0;
	    z-index: 9999999;
	    padding-top: 3px;
	    padding-bottom: 0px;
	    height: 64px;
	    box-shadow: 0 0 15px 1px rgba(0,0,0,0.07);

	    .navbar__desktop-logo {
		    top: 14px;
		    position: relative;
			width: 115px;
			height: 33px;
			background-repeat: no-repeat;
			background-size: contain;
	    }

	    .navbar__sub-menu {
	    	top: 40px;
	    }

	    .navbar__link {
    	    height: 40px;
	    }
	    .navbar__link:hover {
	    	color: black !important;
	    }

	}
}

.invisible-on-mobile {
	display: none;
}
.desktop-only {
	display: block;
}
.mobile-only {
	display: none;
}

.navbar {
  max-width: 1210px;
  margin: 0 auto;
  color: white;
  display: flex;
  min-height: 0;
  border: none;
  user-select: none;
  z-index: 100;

  &__items {
  	display: flex;
  	padding-right: 10px;
  	padding-left: 30px;
  	&-right {
  		margin-left: auto;
  		position: relative;
			top: 17px;
  	}
  	.navbar__link span {
  		// cursor: pointer;
  	}
  	.navbar__link:hover {
  		color: #383838;
  	}
  	.navbar__link span:active {
  		// opacity: 0.7;
  	}
  }
  &__link-brand {
		padding-left: 40px;
		cursor: pointer;
	}
  &__link {
  	margin-right: 35px;
  	color: #2F8D2F;
  	letter-spacing: 1.6px;
  	&-toggle {
  		display: none;
  	}
  	.glyphicon-menu-down, .glyphicon-menu-up {
  		color: #A5C598;
  		margin-left: 5px;
  	}
  }
	&__desktop-logo {
		display: black;
		background-image: url('./logo-inverted.png');
		width: 202px;
		height: 58px;
		background-size: contain;
		background-repeat: no-repeat;
		transition: all 0.75s;
		top: 0px;
		position: relative;
	}
	&__mobile-logo {
		display: none;
	}
	&__sub-menu {
		position: absolute;
    top: 56px;
    text-align: center;
    text-transform: uppercase;
    background-color: #28792e;
    opacity: 0.8;
    color: white;
    ul {
    	margin: 0;
    	padding: 0;
    	li {
		    padding: 8px 20px 8px 20px;
		    cursor: pointer;
    	}
    	li:hover {
		    background-color: #e0e0e0;
    		color: black;
    	}
    }
  	.arrow-up {
	    width: 0;
	    height: 0;
	    border-left: 20px solid transparent;
	    border-right: 20px solid transparent;
	    border-bottom: 14px solid #28792e;
	    opacity: 1;
	    top: -14px;
	    position: absolute;
	    left: 15px;
		}
	}
	&__sub-menu-mobile {
		display: none;
	}
}


@media only screen and (max-width: 768px) {
	.invisible-on-mobile {
		display: inline-block;
		visibility: hidden;
	}
	.navbar {
		flex-direction: column;
		&__items {
			flex-direction: column;
			display: none;
			padding: 0;
			&-right {
				margin-left: 0;
				position: relative;
				top: 0px;
				/* background color of the menu when in mobile mode*/
				background-color: #F2F2F2; 
   			width: 100%;
			}
		}
		&__link-brand {
			padding-left: 25px;
			padding-bottom: 20px;
		}
		&__toggle-show {
			display: flex;
	    margin: 0 auto;
	    .navbar__link {
	    	border-bottom: 1px solid #e4e4e4; 
    		padding: 15px 0px 15px 0px;
	    }
		}
		&__link-toggle {
			align-self: flex-end;
	    display: initial;
	    position: absolute;
	    cursor: pointer;
	    font-size: 25px;
    	top: 12px;
    	color: black;
    	padding-right: 15px
		}
		&__link {
			text-align: center;
			margin-right: 0;
			.glyphicon-menu-down {
				font-size: 10px;
		    position: relative;
		    top: -1px;
			}
			.glyphicon-menu-up {
				font-size: 10px;
		    position: relative;
		    top: 0px;
			}
		}
		&__sub-menu {
			display: none;
		}
		&__sub-menu-mobile {
			display: block;
			top: 56px;
	    text-align: center;
	    text-transform: uppercase;
	    ul {
	    	margin: 0;
	    	padding: 15px 0px 0px 0px;
	    	li {
	    		color:  #2F8D2F;
			    padding: 8px;
			    background-color: #e0e0e0;
			    margin-bottom: 4px;
			    cursor: pointer;
	    	}
	    	li:hover {
	    		background: #b1b1b1;
    			color: white;
	    	}
	    }
		}
	}
	.desktop-only {
		display: none;
	}
	.mobile-only {
		display: block;
	}
}

@media only screen and (max-width: 599px) {
	.navbar {
		&__link-brand {
			padding-left: 10px;
		}
		&__link-toggle {
			align-self: flex-end;
	    display: initial;
	    position: absolute;
	    cursor: pointer;
	    font-size: 20px;
	    top: 4px;
	    color: white;
		}
		&__link-toggle:active {
			color: #2F8D2F;
		}
		&__desktop-logo {
			display: none;
		}
		&__mobile-logo {
			display: block;
		}
	}
}


/* Responsible for making the font smaller in the menu */
@media only screen and (max-width: 840px) and (min-width: 768px) {
	.navbar {
		&__link {
			font-size: 13px;
			margin-right: 21px;
		}
	}
}

@keyframes pulldownmenu {
  from {
  	top: -100px;
  }
  to {
  	top: 0px;
  }
}