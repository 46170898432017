.locations-container {
	width: 100%;
	background-color: #313131;

	&__icon {
		padding-top: 40px;
		width: 44px;
		text-align: center;
		margin: 0 auto;
		@media only screen and (max-width: 560px) {
			width: 40px;
		}
		img {
			width: 100%;
		}
	}
	&__header {
		font-size: 25px;
		text-align: center;
		color: #f1f1f1;
		padding-top: 11px;

		@media only screen and (max-width: 1199px) {
			font-size: 20px;
		}
		
		@media only screen and (max-width: 560px) {
			font-size: 19px;
			padding-top: 15px;
		}
	}

	&__header-border {
		&-top {
			width: 270px;
	    border-top: 1px solid #717171;
	    margin: 0 auto;
	    margin-top: 22px;
			@media only screen and (max-width: 1199px) {
				width: 230px;
			}
		    @media only screen and (max-width: 560px) {
				display: none;
			}
		}
		&-bottom {
			width: 140px;
	    border-top: 1px solid #afafaf;
	    margin: 0 auto;
	    margin-top: 11px;
	    @media only screen and (max-width: 1199px) {
				width: 110px;
			}
		    @media only screen and (max-width: 560px) {
				display: none;
			}
		}
	}

	&__subtext {
		width: 830px;
    font-size: 21px;
    font-weight: 100;
    margin: 0 auto;
    text-align: center;
    padding-top: 25px;
    color: #f1f1f1;

	  @media only screen and (max-width: 900px) {
			width: 85%;
		}
		@media only screen and (max-width: 560px) {
			font-size: 18px;
		}
	}

	&__subtext-locations-desktop {
		padding-top: 20px;
		text-align: center;
		width: 600px;
    margin: 0 auto;
    color: white;
    @media only screen and (max-width: 630px) {
			display: none;
		}
	}

	&__subtext-locations-mobile {
		padding-top: 20px;
		text-align: center;
		max-width: 400px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0 auto;
    color: white;
    @media only screen and (min-width: 631px) {
			display: none;
		}
	}

	&__subtext-locations-row-2 {
		margin-top: 20px;
	}

	&__single-location {
		border: 1px solid white;
    padding: 7px;
    letter-spacing: 0.6px;
    font-weight: 100;
	}


	&__google-maps-container {
		margin: 0 auto;
		text-align: center;
		margin-top: 20px;
		// height: 500px;
		width: 100%;
		position: relative;
	}

	&__google-maps {
		width: 100%;
		height: 380px;
		overflow: hidden;
		iframe {
			width: 100% !important;
			position: relative;
	    /*top: -50px;*/
	    border: none;
		}	
	}
}