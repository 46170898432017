.contact-page {
	#my-recaptcha-placeholder {
		height: 88px;
		padding-bottom: 10px;
	}
	&__google-maps-container {
	    text-align: center;
    	.google-maps-component__check-location {
    		color: black;
    		font-size: 17px;
			font-weight: 300;
			line-height: 26px;
			padding-top: 0;
			&-description {
				margin-bottom: 10px;
			}
    	}
    	.google-maps-component__valid, .google-maps-component__invalid {
    		color: black;
    	}
    	.google-maps-component__error {
    		color: red;
    	}
    	input {
    		margin-top: 0px;
			border: 1px solid #c7c7c7;
		    padding: 8px;
		    padding-left: 13px;
		    border-radius: 3px;
		    border-top-right-radius: 0px;
		    border-bottom-right-radius: 0px;		
    	}
    	.google-maps-component__check-btn {
    		 background: #afb36d;
    	}
    	.google-maps-component__check-btn-loading {
    		 background: #afb36d;
    	}
    }
	&__body {
	    flex: 1 1 auto;
	    max-width: 1210px;
	    margin: 0 auto;
	    display: flex;
	    justify-content: space-between;
	    padding: 0;

	    &-left {
	    	flex: 0 0 30%;
		    order: -1;
		    max-width: 275px;
		    margin-left: 20px;
		    padding-top: 20px;
		    @media only screen and (max-width: 768px) {
				display: none;
			}
	    }

	    &-right {
	    	flex: 1 1 auto;
		    max-width: 850px;
		    width: 100%;
		    margin-left: 20px;
		    margin-right: 20px;
		    /*padding-bottom: 210px;*/
	    }

	    &-menu {
	    	&-fixed {
		    	position: fixed;
		    	top: 90px;
		    	width: 275px;
		    	z-index: 0;
		    	@media only screen and (max-width: 940px) {
					width: 230px;
				}
		    }
		    &-special-offers {
		    	text-align: center;
		    	padding-bottom: 20px;
		    	font-size: 20px;
		    	font-weight: 800;
		    	color: #b5b5b5;
		    }
		    &-discount {
		    	border: 2px dashed gray;
		    	padding: 10px;
		    	padding-top: 20px;
		    	padding-bottom: 20px;
		    	margin-bottom: 20px;
		    	min-height: 3000px;
		    	&-header {
		    		text-align: center;
		    		font-size: 20px;
		    		color: #5fbc60;
		    		text-transform: uppercase;
		    	}
		    	&-content {
		    		padding-top: 10px;
		    		text-align: center;
		    		font-size: 15px;
		    		padding-left: 15px;
		    		padding-right: 15px;
		    		font-weight: 100;
		    	}
		    	&-redeem {
		    		margin: 0 auto;
		    		text-align: center;
		    		margin-top: 20px;
		    		&-btn {
		    			height: 40px;
					    padding: 10px 25px 15px 25px;
					    margin: 0 auto;
					    text-align: center;
		    		}
		    	}
		    	&-more {
		    		color: green;
		    		font-size: 15px;
		    		text-align: center;
		    		cursor: pointer;
		    	}
		    	&-more:hover {
		    		opacity: 0.8;
		    	}
		    }
	    	&-link {
	    		padding: 15px;
	    		cursor: pointer;
	    		font-size: 15px;
	    		color: #7F7F7F;
	    		border-bottom: 1px solid #e8e8e8;
	    	}
	    	&-link:hover {
	    		background-color: #f5f5f5;
	    	}
	    	&-link-active {
	    		border-left: 5px solid #afb36d;;
	    		background-color: #f5f5f5;
	    	}
	    	&-backtop {
	    		padding-top: 10px;
	    		text-transform: uppercase;
			    font-size: 11px;
			    letter-spacing: 0.2px;
			    color: gray;
			    cursor: pointer;
			    margin-bottom: 20px;
	    	}
	    	&-backtop:hover {
	    		color: black;
	    	}
	    }

	    &-freeinspection {
			border-bottom: 1px solid #ccc;

	    	@media only screen and (max-width: 768px) {
				padding-bottom: 20px;
			}
	    	padding-bottom: 10px;
	    	h1 {
	    		font-weight: 800;
	    		font-size: 35px;
	    		color: #7F7F7F;
	    		margin-bottom: 20px;
	    		@media only screen and (max-width: 768px) {
					text-align: center;
					margin-top: 0;
					font-size: 25px;
				}
	    	}
	    	&-content {
	    		font-size: 17px;
	    		font-weight: 300;
	    		line-height: 26px;
	    		img {
	    			width: 200px;
	    			float: left;
	    			margin-right: 15px;
	    			padding-top: 5px;
	    			height: 125px;
	    			border-radius: 100px;
	    		}
	    		padding-bottom: 20px;
	    	}

	    	&-form {
	    		padding-right: 15px;
	    		padding: 20px;
	    		padding-top: 10px;
	    		border-radius: 4px;
	    		label {
	    			display: block;
	    			color: gray;
	    			font-weight: 400;
	    			display: none;
	    		}
	    		input {
	    			border: 1px solid #c7c7c7;
				    width: 100%;
				    padding: 10px;
				    margin-bottom: 20px;
				    border-radius: 3px;
	    		}
	    		&-col {
	    			padding-right: 0;
	    		}
	    		&-row {
	    			padding-right: 15px;
	    		}
	    		&-policy {
	    			color: gray;
	    			font-weight: 100;
	    			padding-bottom: 20px;
	    		}
	    		&-contact-btn {
	    			height: 40px;
				    padding: 10px 25px 15px 25px;
				    margin: 0 auto;
				    text-align: center;
				    background: #afb36d;
				    @media only screen and (max-width: 768px) {
						width: 100%;
						display: block;
						.emerald-button-content {
							display: block;
						}
					}
	    		}
	    		&-contact-btn-loading, &-contact-btn-disabled {
	    			background: #c1c39e;
	    		}
	    		&-phone {
	    			img {
	    				display: none;
	    			}
	    			select { 
	    				display: none;
	    			}
	    		}
	    		&-error {
	    			color: red;
	    			padding-top: 4px;
	    		}
	    		&-success {
	    			color: #424242;
				    letter-spacing: 0.1px;
				    padding: 15px;
				    background-color: #38883221;
				    border-radius: 5px;
				    margin-top: 10px;
	    		}
	    	}
    		clear: both;
	    }

	    &-locations {
	    	border-bottom: 1px solid #ccc;
	    	padding-bottom: 30px;
	    	@media only screen and (max-width: 768px) {
				border-bottom: 1px solid #ccc;
				padding-bottom: 30px;
			}
	    	h1 {
	    		font-weight: 800;
	    		font-size: 35px;
	    		color: #7F7F7F;
	    		margin-bottom: 20px;
	    		@media only screen and (max-width: 768px) {
					text-align: center;
					font-size: 25px;
				}

	    	}
	    	&-content {
	    		font-size: 17px;
	    		font-weight: 300;
	    		line-height: 26px;
	    		padding-bottom: 10px
	    	}
	    	&-list {
	    		font-size: 17px;
	    		line-height: 26px;
	    		display: flex;
    			justify-content: center;
    			color: gray;
	    	}
	    }

	    &-offers {
			border-bottom: 1px solid #ccc;
			padding-bottom: 20px;
	    	@media only screen and (max-width: 768px) {
				border-bottom: 1px solid #ccc;
				padding-bottom: 30px;
			}
	    	h1 {
	    		font-weight: 800;
	    		font-size: 35px;
	    		color: #7F7F7F;
	    		margin-bottom: 20px;
	    		@media only screen and (max-width: 768px) {
					text-align: center;
					font-size: 25px;
				}

	    	}
	    	&-content {
	    		font-size: 16px;
	    		font-weight: 300;
	    		line-height: 26px;
	    		padding-bottom: 20px;
	    	}
	    	&-discount {
		    	border: 2px dashed gray;
		    	transition: 500ms background;
		    	padding: 10px;
		    	padding-top: 20px;
		    	padding-bottom: 20px;
		    	margin-bottom: 20px;
		    	&-header {
		    		text-align: center;
		    		font-size: 20px;
		    		color: #afb36d;
		    		text-transform: uppercase;
		    	}
		    	&-content {
		    		padding-top: 10px;
		    		text-align: center;
		    		font-size: 15px;
		    		padding-left: 15px;
		    		padding-right: 15px;
		    		font-weight: 100;
		    	}
		    	&-redeem {
		    		margin: 0 auto;
		    		text-align: center;
		    		margin-top: 20px;
		    		&-btn {
		    			height: 40px;
					    padding: 10px 25px 15px 25px;
					    margin: 0 auto;
					    text-align: center;
					    background: #afb36d;
		    		}
		    		&-arrow-btn {
		    			height: 40px;
					    padding: 10px 12px 15px 12px;
					    margin: 0 auto;
					    text-align: center;
					    background: #afb36d;
		    		}
		    		input:focus {
					    outline-width: 2px;
					}
		    		&-input {
		    			display: inline-block;
					    vertical-align: top;
					    border: 1px solid #e0e0e0;
					    padding: 9px;
					    margin-bottom: 2px;
					    border-radius: 2px 0 0 2px;
					    border-right: none;
				    	@media (max-width:1081px) and (min-width:769px) {
							width: 100%;
						}
		    		}
		    		&-sent {
		    			font-weight: 100;
					    color: green;
					    font-size: 17px;
					    padding: 10px;
		    		}
		    		&-error {
		    			font-weight: 100;
		    			color: red;
		    		}
		    	}
		    }
		    &-discount:hover {
		    	// border: 2px solid gray;
		    	background: #f7f7f7;

		    }
	    }

	    &-reviews {
	    	padding-bottom: 10px;
	    	border-bottom: 1px solid #ccc;

	    	h1 {
	    		font-weight: 800;
	    		font-size: 35px;
	    		color: #7F7F7F;
	    		margin-bottom: 20px;
	    		@media only screen and (max-width: 768px) {
					text-align: center;
					font-size: 25px;
				}
	    	}
	    	&-content {
	    		font-size: 17px;
	    		font-weight: 300;
	    		line-height: 26px;
	    		padding-bottom: 5px;
	    	}
	    	&-links {
	    		text-align: center;
	    		padding-bottom: 10px;
	    		img {
	    			cursor: pointer;
	    			width: 150px;
	    			margin: 10px;
	    			margin-bottom: 20px;
		    		@media only screen and (max-width: 768px) {
						width: 100px;
					}
	    		}
	    		img:hover {
	    			filter: brightness(1.1);
	    		}
	    		img:active {
	    			filter: brightness(0.9);
	    		}
	    	}
	    }

	     &-license { 
	     	padding-bottom: 40px;

	    	h1 {
	    		font-weight: 800;
	    		font-size: 35px;
	    		color: #7F7F7F;
	    		margin-bottom: 20px;
	    		@media only screen and (max-width: 768px) {
					text-align: center;
					font-size: 25px;
				}
	    	}

	    	&-content {
	    		font-size: 17px;
	    		font-weight: 300;
	    		line-height: 26px;
	    		padding-bottom: 5px;
	    	}

	     }
	}
}