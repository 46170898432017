.privacy-policy-page {
	&__body {
		max-width: 1000px;
	    margin: 0 auto;
	    padding-left: 20px;
	    padding-right: 20px;
	    font-size: 17px;
	    font-weight: 300;
	    line-height: 26px;
	    padding-bottom: 20px;
	}
	&__header {
		font-weight: 800;
	    font-size: 35px;
	    color: #7F7F7F;
	    margin-bottom: 20px;
	    padding-top: 20px;
	}
}