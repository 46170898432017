.google-maps-component {
	position: relative;
	padding-bottom: 20px;

	&__check-location {
		color: white;
	    font-size: 19px;
	    font-weight: 100;
	    text-align: center;
	    padding-top: 10px;
	    padding-bottom: 20px;
	    @media only screen and (max-width: 560px) {
	    	font-size: 15px;
	    }
	}

	&__check-location-input {
		background-color: white;
	    color: black;
	    height: 39px;
	    padding: 14px;
	    margin-top: 20px;
	    width: 350px;
	    border-radius: 0;
	    @media only screen and (max-width: 560px) {
	    	width: 200px;
	    }
	}

	&__check-btn-desktop {
		@media only screen and (max-width: 560px) {
	    	display: none;
	  	}

	}
	
	&__check-btn-mobile {
		@media only screen and (min-width: 561px) {
    		display: none;
  		}
  	
	}

	&__check-btn {
		display: inline-table;
	    padding-left: 20px;
	    padding-right: 20px;
	    margin: 0 auto;
	    text-align: center;
	    height: 39px;
	    font-size: 16px;
	    vertical-align: bottom;
	}

	&__check-btn-loading {
		display: inline-table;
	    padding-left: 20px;
	    padding-right: 20px;
	    margin: 0 auto;
	    text-align: center;
	    height: 39px;
	    vertical-align: bottom;
	}

	&__free-inspection-btn {
		display: inline-table;
	    padding-left: 20px;
	    padding-right: 20px;
	    margin: 0 auto;
	    text-align: center;
	    height: 39px;
	    font-size: 16px;
	    vertical-align: bottom;
	    color: white !important;
	}

	&__valid {
		color: white;
		font-weight: 100;
		font-size: 16px;
	    padding-bottom: 18px;
	    span {
	    	color: #4ec14d;
   	 		padding-right: 4px;
   	 		padding-bottom: 10px;
	    }
	    &-link {
	    	a { 
	    		color:#5eb75d;
			    letter-spacing: 0.3px;
			    border-bottom: 1px solid #5eb75d;
			    padding-bottom: 1px;
			    text-decoration: none;
	    	}
	    	a:hover {
	    		text-decoration: none;
	    		opacity: 0.9;
	    	}
	    	a:visited {
	    		text-decoration: none;
	    	}
	    	a:active {
	    		text-decoration: none;
	    	}
	    }
	}

	&__invalid {
		color: white;
		font-weight: 100;
		font-size: 16px;
	    padding-bottom: 16px;
	    padding-left: 16px;
	    padding-right: 18px;
	    a {
	    	color: #06c2ff;
    		text-decoration: underline;
	    }
	}

	&__error {
		color: #ff9191;
		font-weight: 100;
		font-size: 16px;
	    padding-bottom: 18px;

	}

}

.glyphicon-refresh-animate {

    -animation: spin 2s infinite linear;
    -ms-animation: spin 2s infinite linear;
    -webkit-animation: spinw 2s infinite linear;
    -moz-animation: spinm 2s infinite linear;
}

@keyframes spin {
    from { transform: scale(1) rotate(0deg);}
    to { transform: scale(1) rotate(360deg);}
}
  
@-webkit-keyframes spinw {
    from { -webkit-transform: rotate(0deg);}
    to { -webkit-transform: rotate(360deg);}
}

@-moz-keyframes spinm {
    from { -moz-transform: rotate(0deg);}
    to { -moz-transform: rotate(360deg);}
}