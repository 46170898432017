.treatment-page {
	&__body {
	    flex: 1 1 auto;
	    max-width: 1210px;
	    margin: 0 auto;
	    display: flex;
	    justify-content: space-between;
	    padding: 0;

	    &-left {
	    	flex: 0 0 30%;
		    order: -1;
		    max-width: 275px;
		    margin-left: 20px;
		    padding-top: 20px;
		    @media only screen and (max-width: 768px) {
				display: none;
			}
	    }

	    &-right {
	    	flex: 1 1 auto;
		    max-width: 850px;
		    width: 100%;
		    margin-left: 20px;
		    margin-right: 20px;
	    }

	    &-menu {
	    	&-fixed {
		    	position: fixed;
		    	top: 90px;
		    	width: 275px;
		    	z-index: 0;
		    	@media only screen and (max-width: 940px) {
					width: 230px;
				}
		    }
		    &-special-offers {
		    	text-align: center;
		    	padding-bottom: 20px;
		    	font-size: 20px;
		    	font-weight: 800;
		    	color: #b5b5b5;
		    }
		    &-discount {
		    	border: 2px dashed gray;
		    	padding: 10px;
		    	padding-top: 20px;
		    	padding-bottom: 20px;
		    	margin-bottom: 20px;
		    	&-header {
		    		text-align: center;
		    		font-size: 20px;
		    		color: #5fa2bc;
		    		text-transform: uppercase;
		    	}
		    	&-content {
		    		padding-top: 10px;
		    		text-align: center;
		    		font-size: 15px;
		    		padding-left: 15px;
		    		padding-right: 15px;
		    		font-weight: 100;
		    	}
		    	&-redeem {
		    		margin: 0 auto;
		    		text-align: center;
		    		margin-top: 20px;
		    		&-btn {
		    			background: #5fa2bc;
		    			height: 40px;
					    padding: 10px 25px 15px 25px;
					    margin: 0 auto;
					    text-align: center;
		    		}
		    	}
		    	&-more {
		    		color: #5fa2bc;
		    		font-size: 15px;
		    		text-align: center;
		    	}
		    	&-more:hover {
		    		opacity: 0.8;
		    	}
		    }
	    	&-link {
	    		padding: 15px;
	    		cursor: pointer;
	    		font-size: 15px;
	    		color: #7F7F7F;
	    		border-bottom: 1px solid #e8e8e8;
	    	}
	    	&-link:hover {
	    		background-color: #f5f5f5;
	    	}
	    	&-link-active {
	    		border-left: 5px solid #5fa2bc;;
	    		background-color: #f5f5f5;
	    	}
	    	&-backtop {
	    		padding-top: 10px;
	    		text-transform: uppercase;
			    font-size: 11px;
			    letter-spacing: 0.2px;
			    color: gray;
			    cursor: pointer;
			    margin-bottom: 20px;
	    	}
	    	&-backtop:hover {
	    		color: black;
	    	}
	    }

	    &-services {

	    	@media only screen and (max-width: 768px) {
				padding-bottom: 20px;
			}

			&-intro {
				font-size: 16px;
		    	padding-top: 20px;
		    	padding-bottom: 5px;
	    		line-height: 23px;
	    		font-weight: 100;
	    		color: gray;
			}
	    	
	    	h1 {
	    		font-weight: 800;
	    		font-size: 35px;
	    		color: #7F7F7F;
	    		margin-bottom: 0px;
	    		@media only screen and (max-width: 768px) {
					text-align: center;
					margin-top: 0;
					font-size: 25px;
				}
	    	}

	    	&-single {
	    		padding-top: 10px;
	    		padding-bottom: 20px;
	    		border-bottom: 1px solid #ccc;
	    		h4 {
	    			font-size: 20px;
	    			padding-bottom: 10px;
		    		@media only screen and (max-width: 768px) {
		    			text-align: center;
		    		}
	    		}
		    	&-content {
		    		font-size: 17px;
		    		font-weight: 300;
		    		line-height: 26px;
		    		min-height: 270px;
		    		&-picture {
		    			width: 400px;
		    			float: left;
		    			margin-right: 15px;
		    			border-radius: 3px;
		    			height: 274px;
		    			background-image: url('images/power-spray.jpg');
		    			background-size: cover;
    					background-position: center;
    					&-granules {
    						float: right;
    						background-image: url('images/granules.jpg');
    						margin-left: 15px;
    					}
    					&-dustinjections {
    						background-image: url('../../components/FiveStep/images/dust-injections-2.jpg');
    					}
    					&-garage {
    						float: right;
    						background-image: url('images/garage-2.jpg');
    						margin-left: 15px;
    					}
    					&-deweb {
    						background-image: url('../../components/FiveStep/images/deweb.jpg');
    					}
    					&-blacklightscorpion {
    						background-image: url('images/scorpion-blacklight.jpg');
    					}
    					&-beehive {
    						float: right;
    						background-image: url('images/beehive.jpg');
    						margin-left: 15px;
    					}
		    			@media only screen and (max-width: 598px) {
							width: 100%;
							margin-bottom: 15px;
							margin-left: 0 !important;
							float: left !important;
						}
		    		}
		    	}
	    	}

	    	&-granules, &-garage, &-beeremoval {
	    		img {
	    			float: right;
	    			margin-left: 15px;
	    		}
	    	}
	    	&-antgel {
	    		.treatment-page__body-services-single-content {
		    		min-height: 100px !important;
	    		}
	    	}
    		clear: both;
	    }

	    &-kid {

	    	@media only screen and (max-width: 768px) {
				padding-bottom: 20px;
			}
	    	
	    	h1 {
	    		font-weight: 800;
	    		font-size: 35px;
	    		color: #7F7F7F;
	    		@media only screen and (max-width: 768px) {
					text-align: center;
					margin-top: 0;
					font-size: 25px;
				}
	    	}

	    	&-content {
	    		font-size: 17px;
	    		font-weight: 300;
	    		line-height: 26px;
	    		padding-bottom: 30px;
	    	}
	    	

	    }

	}
}